import { Box } from "@chakra-ui/react"
import React from "react"
import Seo from "../components/seo"
import { useDelayedAnalyticsEventOnLand } from "../hooks/analytics"
import terms from "../static/terms.pdf"
import { AnalyticsEvents } from "../services/types"

const TermsPage = () => {
  useDelayedAnalyticsEventOnLand(AnalyticsEvents.terms_of_use_landed)
  return (
    <Box h="100vh" w="100vw">
      <Seo title="Terms of Use" />
      <embed src={terms} height="100%" width="100%" />
    </Box>
  )
}

export default TermsPage
