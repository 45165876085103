import { useEffect } from "react"
import { sendDelayedAmplitudeEvent } from "../services/amplitude"
import { AnalyticsEvents } from "../services/types"

export const useDelayedAnalyticsEventOnLand = (
  eventName: AnalyticsEvents,
  eventProps: object = {}
) => {
  useEffect(() => {
    ;(async () => {
      sendDelayedAmplitudeEvent(eventName, eventProps)
    })()
  }, [])
}
